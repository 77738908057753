<template>
  <div>
    <h1>About and Contact</h1>
    <p>I'm Dexter Lohnes. I'm a lifelong lover of games and technology.
      I enjoy the craft of designing and building games and software, but for me the most important thing is the human
      experience that my work facilitates.
      I hope that the things I create will provide moments of joy and beauty to those who use them.
      Besides making games, I'm also a musician, tango dancer, forager, and 3D modeler.</p>
    <br/>
    <p><a href="mailto:dexterlohnes@gmail.com">Click here to email me</a></p>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

/*h1 {*/
/*  text-align: left;*/
/*  padding-left: 1.5%;*/
/*  font-size: 5em;*/
/*  margin: 0;*/
/*}*/

p {
  text-align: center;
  width: 50%;
  margin: auto;
}

</style>