<template>
  <div>
    <h1>{{ title }}</h1>
    <div class="grid-container">
      <ResumeItem v-for="item in items" v-bind:key="item.name" :item="item"/>
    </div>
  </div>
</template>

<script>
import ResumeItem from "@/components/ResumeItem";

export default {
  name: "ItemsGrid",
  components: {
    ResumeItem
  },
  props: {
    title: String,
    items: Array
  }
}
</script>

<style scoped>

h1 {
  text-align: left;
  padding-left: 1.5%;
  font-size: 5em;
  margin: 0;
}

.grid-container {
  display: grid;
  grid-gap: 50px 0;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

/* Phones */
@media only screen
and (max-device-width: 667px)
and (orientation: portrait) {
  .grid-container {
    display: grid;
    grid-gap: 50px 0;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  h1 {
    text-align: left;
    padding-left: 4.5%;
  }
}

</style>
