<template>
  <div id="app">
    <Biography/>
    <NavBar :links="links"/>
    <ItemsGrid id="games" title="Games" :items="games"></ItemsGrid>
<!--    <ItemsGrid id="apps" title="Apps" :items="apps"></ItemsGrid>-->
    <ItemsGrid id="immersive" title="Immersive" :items="immersive"></ItemsGrid>
    <EndorsementGrid id="endorsements" :items="endorsements"></EndorsementGrid>
    <About id="about"></About>
  </div>
</template>

<script>
import ItemsGrid from "@/components/ItemsGrid";
import Biography from "@/components/Biography";
import EndorsementGrid from "@/components/EndorsementGrid";
import NavBar from "@/components/NavBar";
import About from "@/components/About";

export default {
  name: 'App',
  components: {
    About,
    NavBar,
    Biography,
    ItemsGrid,
    EndorsementGrid
  },
  data: function () {
    return {
      links: [
        {
          text: "Games",
          href: "#games"
        },
        // {
        //   text: "Apps",
        //   href: "#apps"
        // },
        {
          text: "Immersive",
          href: "#immersive"
        },
        {
          text: "Endorsements",
          href: "#endorsements"
        },
        {
          text: "About",
          href: "#about"
        }
      ],
      games: [
        {
          name: "HoloVista",
          image: "holovista.jpeg",
          description: `I was one of two developers on HoloVista, an award-winning narrative driven AR Hidden Object game. ` +
              `I completed gameplay, UI, and graphics programming in JavaScript, React Native and native iOS ` +
              `and supported game flow and narrative design. I loved building this game with this team ` +
              `and am very proud of it. Check it out!`,
          tags: ["game", "iOS"],
          role: ["Developer"],
          link: "https://www.enterholovista.com"
        },
        {
          name: "A Tango Story",
          image: "tango_story.jpg",
          description: "A Tango Story is a 5-10 minute long interactive fiction that lets you spend an evening dancing tango. " +
              "I appreciate when games respect their players' time so I really wanted to create something short but rich. " +
              "I'm very happy with how it turned out and the response it has received.",
          tags: ["web", "interactive fiction"],
          role: ["Sole Creator"],
          link: "https://properlygames.itch.io/tangostory"
        },
        {
          name: "World of Goo",
          image: "wog.jpg",
          description: "I worked with the original developer of World of Goo " +
              "to do some substantial updates on World of Goo iOS when Apple's " +
              "SDK changes required a new version to be pushed to the App Store.",
          tags: ["game", "iOS"],
          role: ["Developer"],
          link: "https://apps.apple.com/us/app/world-of-goo/id401301276"
        },
        {
          name: "Spose: King of Maine",
          image: "spose.jpg",
          description: "I served as a programmer and production consultant to bring Spose: King of Maine into its public release." +
              "The people working on the game were a dedicated but relatively inexperienced group, and they hired me " +
              "to ensure that the game made it over the finish line.",
          tags: ["game", "android", "iOS", "unity"],
          role: ["Mobile Development Consultant"],
          link: "https://play.google.com/store/apps/details?id=com.pdank.spose"
        },
        {
          name: "Operation: Reach",
          image: "reach.jpg",
          description: "I worked as the sole programmer on a re-write of the narrative game Operation: Reach, a game produced by The Boys and Girls Club of America to help teach kids skills for coping with stress and anxiety. " +
              "I implemented new features, gameplay triggers, and narrative content in Unity while working alongside a writer and project manager.",
          tags: ["game", "android", "iOS", "unity"],
          role: ["Developer"],
          link: "https://itunes.apple.com/us/app/operation-reach/id1099308757?mt=8"
        },
        {
          name: "Seul",
          image: "seul.jpg",
          description: "Seul is a small Flash game and my first published solo project. Unfortunately, due to the death " +
              "of Flash Seul is no longer accessible. It involved a mechanic " +
              "where players clicked on twinkling 8-bit stars to explore a short narrative. " +
              "Players had to find the one star on the screen that would progress the narrative by listening " +
              "to how the background music shifted between their ear phones as they moved the mouse around the screen.",
          tags: ["game", "web"],
          role: ["Sole Creator"]
        },
        {
          name: "Find Kelly",
          image: "find_kelly.jpg",
          description: "Find Kelly was an immersive / physically-based game in Sonoma, CA where players " +
              "solved the kidnapping of a young soothsayer by following clues she hid before her disappearance. The game took players on a journey through various " +
              "historical and natural landmarks around the downtown area. Creating the game " +
              "was a challenging and fun experience with puzzle and game design. ",
          tags: ["game", "immersive"],
          role: ["Sole Creator"]
        },
        {
          name: "Zynga Poker",
          image: "zynga_poker.jpg",
          description: "On Zynga Poker I served as a game designer and product-manager. I co-designed " +
              "a successful feature which incentivized repeat purchases. I also tracked and reported on " +
              "the health of our in-game economy and made recommendations on new features.",
          tags: ["game", "iOS", "android"],
          role: ["Designer"],
          link: "https://apps.apple.com/us/app/zynga-poker-texas-holdem/id354902315"
        },
        {
          name: "Mafia Wars 2",
          image: "mafia_wars_two.jpg",
          description: "Mafia Wars 2 was my first professional title I worked on after college. " +
              "I had a fun time making content for the game and was happy to apply my technical expertise as a technical liason for our design team. ",
          tags: ["game", "web"],
          role: ["Designer"]
        },
        {
          name: "Stack & Deploy",
          image: "snd.jpg",
          description: "Stack & Deploy was a mobile " +
              "real-time strategy game similar to Clash Royale which my team created for our senior thesis " +
              "project at University of California, Santa Cruz. Not only did we create a real-time mobile game at a time when " +
              "there were next to no libraries to help us out, we also took second place in the annual " +
              "UCSC business plan competition!",
          tags: ["game", "iOS"],
          role: ["Developer", "Designer", "Producer"]
        },
        {
          name: "TINTS",
          image: "tints.jpg",
          description: "TINTS is an interactive fiction project I started working on in 2015. " +
              "I had a great time building out the world and story of a shapeshifter so starved for love and identity " +
              "that they steal the lives of others and live them out in their place. " +
              "A prototype was playable on Facebook Messenger for many years, but unfortunately it is no longer available.",
          tags: ["prototype", "web", "interactive fiction"],
          // link: "https://www.facebook.com/TINTSGame/",
          role: ["Sole Creator"]
        },
        // {
        //   name: "Porgies",
        //   image: "porgies.jpg",
        //   description: "This is a description of my latest game!",
        //   tags: ["prototype", "unity"],
        //   role: ["Sole Creator"]
        // },
      ],
      apps: [
        {
          name: "Tally",
          image: "tally.jpg",
          description: "I was one of two developers building the Tally Android application, a credit-card management app. " +
              "Day to day I was responsible for costing and building " +
              "feature for our Android app, but I also contributed to design discussions, made improvements to our production processes, " +
              "and even filled in on the iOS side of things when we became unexpectedly shorthanded.",
          tags: ["app", "android"],
          role: ["Developer"],
          link: "https://play.google.com/store/apps/details?id=com.meettally"
        },
        {
          name: "Epic!",
          image: "epic.jpg",
          description: "As one of three members of the Android team at Epic! I helped implement some large redesigns of the core functionality of the Epic! app (it's kind of like Netflix for children's books). I was also lucky enough to be able to spend some time mentoring a more junior member of the team.",
          tags: ["app", "android"],
          role: ["Developer"],
          link: "https://play.google.com/store/apps/details?id=com.getepic.Epic"
        },
        {
          name: "CloudApp",
          image: "cloud_app.jpg",
          description: "At CloudApp I served as interim engineering manager in addition to developing features. Among other things, I was wholly responsible for implementing the app's built-in video editor.",
          tags: ["app", "osx"],
          role: ["Developer"],
          link: "https://apps.apple.com/us/app/cloudapp/id417602904?mt=12"
        },
        {
          name: "Stitcher",
          image: "stitcher.jpg",
          description: "At Stitcher I served as one of two Android engineers. My work included implementing or overhauling multiple major features, including a full redesign of our timeline. During my time at Stitcher I focused a lot on UI/UX and worked very closely with our design team to implement pixel perfect layouts and animations.",
          tags: ["app", "android"],
          link: "https://play.google.com/store/apps/details?id=com.stitcher.app&hl=en",
          role: ["Developer"]
        },
      ],
      immersive: [
        {
          name: "The Latitude Society",
          image: "latitude.jpg",
          description: "I created public art happenings, designed and led experiences, and hosted salon style discussions for The Latitude Society, an immersive alternate-reality experience in San Francisco.",
          tags: ["immersive"],
          role: ["Designer", "Facilitator"],
          link: "https://nonchalance.com/latitude.html"
        },
        {
          name: "The Speakeasy SF",
          image: "speakeasy.jpg",
          description: 'I helped design narrative-driven "audience adventure" side quests for The Speakeasy, an interactive theater in San Francisco.',
          tags: ["immersive"],
          role: ["Designer"],
          link: "https://www.thespeakeasysf.com"
        },
      ],
      endorsements: [
        {
          name: "Nadya Lev",
          company: "Aconite",
          image: "nadya.jpg",
          text: "Dexter is a thoughtful, creative, hard-working and highly efficient game dev. " +
              "His problem-solving skills, positive demeanor, dedication, sense of humor and lateral thinking " +
              "helped to elevate our game to the next level. Any team would be lucky to work with him!"
        },
        {
          name: "Ron Carmel",
          company: "2D Boy / World of Goo",
          image: "ron.jpg",
          text: "Dexter has been wonderful to work with. He's technically competent, reliable, a deep thinker, clear communicator, and a super high integrity human."
        },
        {
          name: "Andrew Hoydich",
          company: "Tally Inc.",
          image: "andrew.jpg",
          text: "There’s no doubt Dexter is a top tier developer, not only because of his professional skills but because of his joy, his humor, and his ability to befriend & amplify anyone he is around. Get him on your team if you need something built!"
        },
        {
          name: "Meredith Hall",
          company: "Film Victoria",
          image: "merry.jpg",
          text: "Dexter is one of the best developers I've had the chance to work with - thoughtful, patient, " +
              "considerate of the entire development process when determining the best course of action. " +
              "Great communicator and skilled at finding unique solutions to difficult problems."
        },
      ]
    }
  }
}
</script>

<style>
#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 60px auto auto;
  max-width: 1920px;
  padding-bottom: 100px;
}

</style>
