<template>
  <div id="bio">
    <img alt="Me, Dexter Lohnes" src="../assets/me_and_big_owl.jpg">
    <div>
      <h1>Hi, I'm Dexter Lohnes.<br>I design and develop games.</h1>
      <p>You're probably here because you're asking yourself "Is this human being capable of doing the things he claims
        to be able to do, and would I enjoy working with him?"
        Well, considering this website is <span style="text-decoration: line-through">essentially propaganda</span> an
        unbiased,
        objective history of my professional work, I'd say you're in the right place. Read on!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Biography"
}
</script>

<style scoped>
#bio {
  width: 50%;
  margin: auto;
  display: flex;
}

#bio img {
  height: 200px;
  border-radius: 50%;
}

#bio h1, p {
  margin-left: 2%;
  text-align: left;
}

#bio h1 {
}

#bio p {

}

/* Phones */
@media only screen
and (max-device-width: 667px) {

  #bio {
    width: 100%;
    margin: auto;
    display: inherit;
  }

  #bio img {
    width: 40vw;
    height: 40vw;
  }
  #bio h1 {
    text-align: center;
  }

  #bio p {
  }
}
</style>
