<template>
  <div class="endorsement">
    <img alt="The face of someone who has nice things to say about me" :src='require(`@/assets/endorsements/${item.image}`)'>
    <div>
      <p class="quote">"{{ item.text }}"</p>
      <div class="attribution-container">
        <p class="attribution">- {{item.name}} <span class="company">({{item.company}})</span></p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Endorsement",
  props: {
    item: {
      name: String,
      company: String,
      image: String,
      text: String
    }
  }
}
</script>

<style scoped>

.endorsement {
  display: flex;
  padding: 0 3.33%;
}

img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

p {
  text-align: left;
}

.quote {
  font-size: 1.35em;
  margin-left: 20px;
  font-style: italic;
}

.attribution {
  margin-left: 2em;
  font-size: 1.25em;
}

.company {
  font-size: .75em;
}

/* Phones */
@media only screen
and (max-device-width: 667px)
and (orientation: portrait) {
  .endorsement {
    display: initial;
    padding: 0 3.33%;
  }

  .attribution {
    margin-left: 20px;
    font-size: 1.25em;
  }
}

</style>
