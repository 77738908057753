<template>
  <div id="navbar">
    <div class="text-button" v-bind:key="link.text" v-for="link in links">
      <a :href="link.href">{{ link.text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    links: Array
  }
}
</script>

<style scoped>
  #navbar {
    display: inline-flex;
    margin: 50px 0 auto;
  }

  .text-button {
    margin: 0 20px;
  }
</style>