<!--suppress CssUnusedSymbol -->
<template>
  <div class="grid-item">
    <a :href="item.link" target="_blank"><img class="item-image" :src='require(`@/assets/resumeItems/${item.image}`)' alt="Screenshot"/></a>
    <span v-for="tag in item.tags" v-bind:key="tag" class="tag" :class="tag">{{ tag }}</span>
    <a :href="item.link" target="_blank"><h3 class="item-name">{{ item.name }}</h3></a>
    <p class="item-description">{{ item.description }}</p>
  </div>
</template>

<script>
export default {
  name: "ResumeItem",
  props: {
    item: {
      name: String,
      tags: Array,
      description: String,
      image: String,
      link: String
    }
  }
}
</script>

<style scoped>

.grid-item {
  padding: 0 5%;
}

.item-image {
  width: 100%;
  object-fit: contain;
}

p, h3, h4 {
  text-align: left;
}

.item-name {
  font-size: 28px;
  color: green;
}

.item-description {
  font-size: 14px;
}

.tag {
  float: left;
  text-align: left;
  margin-right: 10px;
  text-transform: uppercase;
  background-color: lightgrey;
  padding: 0 4px;
  font-weight: bold;
}

.tag.iOS {
  background-color: #0172CA;
  color: white;
  text-transform: none;
}

.tag.game {
  background-color: saddlebrown;
  color: white;
}

.tag.android {
  background-color: #3DDC84;
}

.tag.unity {
  background: white;
  color: black;
  outline: 2px black solid;
  outline-offset: -2px;
}

.tag.web {
  background-color: #19A15F;
  color: white;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: blueviolet;
}

</style>
